import { render, staticRenderFns } from "./ChuangYouXiangMu.vue?vue&type=template&id=5634dfb2&scoped=true"
import script from "./ChuangYouXiangMu.vue?vue&type=script&lang=js"
export * from "./ChuangYouXiangMu.vue?vue&type=script&lang=js"
import style0 from "./ChuangYouXiangMu.vue?vue&type=style&index=0&id=5634dfb2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5634dfb2",
  null
  
)

export default component.exports