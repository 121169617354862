<template>
  <div class="TongZhiGongGao">
    <div class="tzgg">
      <div class="mianbaoxie">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="mianbaoxie2"
        >
          <el-breadcrumb-item class="p1">您的位置：</el-breadcrumb-item>
          <el-breadcrumb-item class="p2" :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="p3">通知公告</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="liebiao">
        <div
          class="liebiao1"
          v-for="(item, index) in tzggArrList"
          :key="index + 18"
          @click="geotoDetial(item)"
        >
          <div class="liebiao2" style="cursor: pointer">
            <div
              style="
                width: 800px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <div class="liebiao3">{{ item.title }}</div>
            </div>
            <div class="liebiao4">{{ item.createTime.split(" ")[0] }}</div>
          </div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import http from "@/utils/http";

export default {
  name: "TongZhiGongGao",
  components: {
    Footer,
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      tzggArrList: "",
      tableData: [
        {
          url: "src/imag/zixundongtai/img.png",
          name: "省卫生健康委组织委直属机关党员干部集中观看中共三大题材话剧《春园·1923》",
          time: "2023-07-07",
        },
        { url: "src/imag/zixundongtai/img.png", name: "1", time: "2023-07-07" },
      ],
      menuType: ''
    };
  },
  created() {
    if(this.$route.query.menuType){
      this.menuType = this.$route.query.menuType
    } else {
      this.menuType = ''
    }
    this.tzggArr();
  },
  methods: {
    geotoDetial(item) {
      this.$router.push(
        "/zixundongtaixiangqing?id=" + item.id + "&type=1&type2=1"
      );
    },
    handleCurrentChange(currentPage) {
      console.log("currentPage", currentPage);
      // 处理页码切换事件
      this.currentPage = currentPage;
      this.tzggArr();
    },
    tzggArr() {
      http
        .get("/api/public/article/page/", {
          params: {
            type: 2,
            timestamp: new Date().getTime(),
            current: this.currentPage,
            size: 10,
            menuType: this.menuType
          },
        })
        .then((response) => {
          // 处理响应数据
          this.tzggArrList = response.data.records;
          this.total = response.data.total;
          console.log("---------", this.tzggArrList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    gotoUrl(router) {
      this.$router.push(router);
    },
  },
};
</script>

<style scoped>
.TongZhiGongGao {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.tzgg {
  background: #ffffff;
  width: 1440px;
  height: 979px;
  opacity: 1;
  margin: 0 auto;
  margin-top: -300px;
  /* 自动布局 */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
  gap: 32px;

  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.mianbaoxie {
  width: 100%;
  height: 40px;
  display: flex;
  justify-items: center;
}
.liebiao {
  width: 100%;
  height: 800px;
  display: flex;

  flex-direction: column;
}
.fenye {
  margin: 0 auto;
  display: flex;
  //width: 275px;
  height: 27px;
}
.mianbaoxie2 {
  width: 300px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  flex-direction: row;
}
.p1 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}
.p2 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}
.p3 {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 16px;
  color: #444444;
}
.liebiao1 {
  width: 1440px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.liebiao1:hover {
  background: #efefef;
}
.liebiao2 {
  margin: 0 auto;
  width: 1376px;
  height: 64px;
  opacity: 1;
  /* 自动布局 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.liebiao3 {
  width: 800px;
  height: 18px;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  /* 文字/666 */
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.liebiao3:hover {
  cursor: pointer;
  color: #024395;
}
.liebiao4 {
  width: 200px;
  height: 18px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  /* 文字/888 */
  color: #888888;
  display: flex;
}
@media screen and (max-width: 1440px) {
  .tzgg {
    width: 1200px;
  }
  .liebiao1 {
    width: 1200px;
  }
  .liebiao {
    width: 1200px;
  }
  .liebiao2 {
    width: 1032px;
  }
}
</style>
