<template>
  <div class="ZhuanJiaLieBiao">

    <div class="lunboback">
      <div class="lunboback1">
        <el-carousel height="300px" indicator-position="none" v-if="BannerList.length > 0" arrow="always">
          <el-carousel-item
              v-for="(item, index) in BannerList"
              :key="index + 20"
          >
            <!-- <img :src="item.url" class="img-lunboback" /> -->
            <img :src="item.image" class="img-lunboback" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="zjlb">
      <div class="zjlb1">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="mianbaoxie">
          <el-breadcrumb-item class="p1">您的位置：</el-breadcrumb-item>
          <el-breadcrumb-item class="p2" :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="p3">专家列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="zjlb2">
        <div class="lie-back">
          <div v-for="(item, index) in zkflList" :key="index">
            <el-link
              @click="selectClass(item, index)"
              class="link1"
              :class="{ 'is-active': selected === index }"
              >{{ item.label }}</el-link
            >
          </div>
        </div>

        <!-- <el-menu
          @select="handleSelect"
          :default-active="activeIndex"
          class="el-menu"
        >
          <el-menu-item
            :index="index"
            class="el-menu1"
            v-for="(item, index) in zkflList"
            :key="index"
            >{{ item.name }}</el-menu-item
          >
        </el-menu> -->
        <el-dropdown class="el-menu2" @command="handleCommand" v-if="flmore.length > 0">
          <span class="el-menu3" > {{ moreName }} </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in flmore"
              :key="index + 17"
              :command="item"
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!--      <el-menu>-->
        <!--        <el-submenu index="10" class="el-menu2">-->
        <!--          <template slot="title" class="el-menu3">更多</template>-->
        <!--          <el-menu-item index="10-1">选项1</el-menu-item>-->
        <!--          <el-menu-item index="10-2">选项2</el-menu-item>-->
        <!--          <el-menu-item index="10-3">选项3</el-menu-item>-->
        <!--          <el-menu-item index="10-4">选项4</el-menu-item>-->
        <!--        </el-submenu>-->
        <!--      </el-menu>-->
      </div>
      <div class="zjlb3" v-if="leaderFlag" style="justify-content: flex-start;">
        <div class="zhuanjiaku2" style="justify-content: center;">
          <div class="zhuanjiakuback" v-for="(item, index) in leader"
               :key="index + 19">
            <div class="zhuanjiaku4" style="height: 430px;">
              <img :src="item.avatar" class="img_zhuanjia" />
              <div class="zhuanjiaku4-1" style="height: 110px;">
                <p class="pp2" style="font-weight: 700; font-size: 18px;">总组长</p>
                <p class="pp2">{{ item.name }}</p>
                <p class="pp3">{{ item.titleName }}</p>
                <p v-if="item.section" class="pp3">{{ item.section }}</p>
                <div class="pp4">{{ item.dept }}</div>
              </div>
            </div>
          </div>

        </div>
        <div class="zhuanjiaku2">
          <div class="zhuanjiakuback" v-for="(item, index) in leaderList"
               :key="index + 19">
            <div class="zhuanjiaku4" style="height: 430px;">
              <img :src="item.avatar" class="img_zhuanjia" />
              <div class="zhuanjiaku4-1" style="height: 110px;">
                <p class="pp2" style="font-weight: 700; font-size: 18px;width: 200px;">{{item.groupFlag === '0' ? '行政管理组' :
                    item.groupFlag === '1' ? '临床医学组' : item.groupFlag === '2' ? '公共卫生组' : item.groupFlag === '3' ?
                        '护理管理组' : item.groupFlag === '4' ? '药事管理组' : ''}} 组长</p>
                <p class="pp2" style="width: 200px;">{{ item.name }}</p>
                <p class="pp3">{{ item.titleName }}</p>
                <p v-if="item.section" class="pp3">{{ item.section }}</p>
                <div class="pp4">{{ item.dept }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zjlb3" v-else>
        <div class="zhuanjiaku2">
          <div class="zhuanjiakuback" v-for="(item, index) in zkfyList"
               :key="index + 19">
            <div class="zhuanjiaku4">
              <img :src="item.avatar" class="img_zhuanjia" />
              <div class="zhuanjiaku4-1">
                <p class="pp2">{{ item.name }}</p>
                <p class="pp3">{{ item.titleName }}</p>
                <p v-if="item.section" class="pp3">{{ item.section }}</p>
                <div class="pp4">{{ item.dept }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="fenye">
        <el-pagination
          background
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import http from "@/utils/http";
export default {
  name: "ZhuanJiaLieBiao",
  components: { Footer },
  data() {
    return {
      BannerList: [],
      scrollDistance: 0, // 已滚动的距离
      currentPage: 1,
      total: 0,
      typeId: null,
      selected: 0,
      flmore: [],
      moreName: "更多 >",
      zkfyList: [],
      specialty: null,
      activeIndex: 0,
      reachedEnd: true,
      activeIndex2: "1",
      keshi: [
        { name: "全科" },
        { name: "外科" },
        { name: "内科" },
        { name: "耳鼻喉科" },
        { name: "肿瘤科" },
        { name: "眼科" },
        { name: "口腔科" },
        { name: "感染科" },
        { name: "检验科" },
      ],
      zkflList: [
        {
          label: '全部',
          value: ''
        },{
          label: '组长',
          value: '999'
        },
        {
          label: '行政管理组',
          value: '0'
        },{
          label: '临床医学组',
          value: '1'
        },{
          label: '公共卫生组',
          value: '2'
        },{
          label: '护理管理组',
          value: '3'
        },{
          label: '药事管理组',
          value: '4'
        }
      ],
      groupFlag: '',
      leaderFlag: false,
      leader: [],
      leaderList: [],
    };
  },
  methods: {
    fetchData() {
      http
          .get("/api/public/banner/list/1", {
            params: {
              timestamp: new Date().getTime(),
            },
          })
          .then((response) => {
            // 处理响应数据
            this.BannerList = response.data;
            console.log("---------", this.BannerList);
          })
          .catch((error) => {
            // 处理错误
            console.error(error);
          });
    },
    handleScroll() {
      const windowHeight = window.innerHeight; // 浏览器窗口的高度
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; // 页面滚动的距离
      const documentHeight = document.documentElement.offsetHeight; // 页面的总高度

      // 判断是否滚动到了页面的一半
      if (scrollTop + windowHeight >= documentHeight / 2) {
        // 加载更多数据的操作
        this.loadMoreData();
      }

      // 更新已滚动的距离
      this.scrollDistance = scrollTop;
    },
    loadMoreData() {
      if (this.total > this.zkfyList.length && this.reachedEnd) {
        this.reachedEnd = false;
        this.currentPage++;
        this.zkfyData();
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    selectClass(item, index) {
      if(item.value === '999'){
        http
            .get("/api/public/expert/leader/list", {
              params: {
                timestamp: new Date().getTime(),
                leaderFlag: '2',
              },
            })
            .then((response) => {
              console.log(response)
              this.leader = response.data;
            })
        http
            .get("/api/public/expert/leader/list", {
              params: {
                timestamp: new Date().getTime(),
                leaderFlag: '1',
              },
            })
            .then((response) => {
              console.log(response)
              this.leaderList = response.data;
            })
        this.leaderFlag = true;
        this.selected = index;
      } else {
        this.moreName = "更多>";
        this.groupFlag = item.value;
        this.selected = index;
        this.currentPage = 1;
        this.leaderFlag = false;
        this.zkfyData();
      }
    },
    handleCommand(command) {
      // console.log("点击值为:", command);
      // this.moreName = command.name;
      this.specialty = command.id;
      this.selected = 1000;
      this.currentPage = 1;
      this.zkfyData();
    },
    handleComman(item) {
      console.log("选项:", item);

      this.moreName = item.name;
      this.specialty = item.id;
      this.selected = 1000;
      this.zkfyData();
    },
    handleCurrentChange(currentPage) {
      // 处理页码切换事件
      this.currentPage = currentPage;
      this.zkfyData();
    },
    zkfyData() {
      if (this.currentPage == 1) {
        this.zkfyList = [];
      }
      http
        .get("/api/public/expert/page", {
          params: {
            timestamp: new Date().getTime(),
            current: this.currentPage,
            size: 15,
            specialty: this.specialty,
            groupFlag: this.groupFlag
          },
        })
        .then((response) => {
          // 处理响应数据
          this.zkfyList = this.zkfyList.concat(response.data.records);
          this.reachedEnd = true;
          this.total = response.data.total;

          console.log("---------", this.zkfyList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    zkflData() {
      http
        .get("/api/public/specialty/list", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.zkflList = [{ name: "全部", id: null }];
          for (let index = 0; index < response.data.length; index++) {
            if (index < 9) {
              this.zkflList.push(response.data[index]);
            } else {
              this.flmore.push(response.data[index]);
            }
          }

          // this.zkflList.push(...response.data);

          console.log("---------", this.zkflList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    this.zkfyData();
    this.fetchData();
    //this.zkflData();
  },
};
</script>
<style>
.el-dropdown-menu {
  display: flex; /* 设置弹出菜单为 flex 布局 */
  flex-wrap: wrap; /* 允许换行 */
  width: 400px !important;
}

.el-dropdown-item {
  flex-basis: 33.33%; /*设置每个 el-dropdown-item 占用 33.33% 的宽度 */
}
</style>
<style scoped>
.lie-back {
  display: flex;
}
.is-active {
  /* background-color: blue; */
  color: #024395 !important;
  border-bottom: 2px solid #024395;
}
.link1 {
  font-size: 18px;
  font-weight: normal;
  line-height: 46px;
  letter-spacing: 0em;

  /* 文字/666 */
  color: #666666;
  display: inline-block;
  width: 110px; /* 设置宽度 */
  white-space: nowrap; /* 不允许换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  margin-right: 18px;
}
.ZhuanJiaLieBiao {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: -300px;
}
.fenye {
  margin: 0 auto;
  display: flex;
  height: 27px;
}
.zhuanjiaku4-1 {
  display: flex;
  width: 275px;
  height: 100px;
  justify-content: space-around;
  flex-direction: column;
}
.zjlb {
  margin: 0 auto;
  display: flex;
  width: 1440px;
  min-height: 1570px;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
}
.zjlb1 {
  width: 100%;
  height: 40px;
  display: flex;
  justify-items: center;
}
.zjlb2 {
  margin: 0px;
  justify-content: flex-start;
  flex-direction: row;
  width: 1360px;
  height: 48px;
  display: flex;
  padding: 0 40px;
  background: linear-gradient(
    90deg,
    rgba(229, 242, 255, 0.8) 0%,
    rgba(179, 213, 255, 0.8) 100%
  );
}
.zjlb3 {
  width: 100%;
  min-height: 1370px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.zhuanjiaku2 {
  margin-top: 8px;
  display: flex;
  width: 100%;
  min-height: 236px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.zhuanjiaku4 {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: #eef6ff;
  padding: 0px;
  justify-items: stretch;
  width: 275px;
  height: 420px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.img_zhuanjia {
  margin: 0 auto;
  display: flex;
  width: 275px;
  height: 320px;
}
.zhuanjiakuback {
  margin: 0 6px;
}
.pp2 {
  text-align: center;
  width: 265px;
  height: 20px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: center;
  color: #212121;
  margin: 0 auto;
  white-space: nowrap; /* 禁止文字换行 */
  overflow: hidden; /* 超出长度时隐藏溢出部分 */
  text-overflow: ellipsis; /* 超出长度时显示省略号 */
}
.pp3 {
  text-align: center;
  width: 265px;
  height: 20px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: center;
  color: #3d3d3d;
  white-space: nowrap; /* 禁止文字换行 */
  overflow: hidden; /* 超出长度时隐藏溢出部分 */
  text-overflow: ellipsis; /* 超出长度时显示省略号 */
  z-index: 2;
  margin: 0 auto;
}
.pp4 {
  text-align: center;
  justify-content: center;
  width: 265px;
  height: 20px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #8e8e8e;

  z-index: 3;
  margin: 0 auto;
}
.mianbaoxie {
  width: 300px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  flex-direction: row;
}
.p1 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}
.p2 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}
.p3 {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 16px;
  color: #444444;
}
.el-menu {
  display: flex;
  box-sizing: border-box;
  /* 百千万/深蓝背景渐变 */
  border-width: 0px 0px 2px 0px;
  align-content: center;
  border-image: linear-gradient(144deg, #394290 54%, #0890ff 84%) 0 0 4 0;
  background: linear-gradient(
    90deg,
    rgba(229, 242, 255, 0.8) 0%,
    rgba(179, 213, 255, 0.8) 100%
  );
}
.el-menu1 {
  align-items: center;
  display: flex;
  color: #024395;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}
.el-menu2 {
  /* width: 94px; */
  height: 46px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* 自动布局 */
  display: flex;
}
.el-menu3 {
  width: 60px;
  height: 48px;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: normal;
  line-height: 46px;
  letter-spacing: 0em;

  /* 文字/666 */
  color: #666666;
}


.lunboback {
  width: 1440px;
  height: 302px;
  display: flex;
  margin: 0 auto;
}
.lunboback1 {
  width: 100%;
  height: 100%;
  ::v-deep .is-active{
      border-bottom: none;
  }
}
.img-lunboback {
  width: 100%;
  height: 298px;
  display: flex;
}

@media screen and (max-width: 1440px) {
  .lunboback {
    width: 1200px;
  }
  .zjlb {
    width: 1200px;
  }
  .zjlb2 {
    width: 1120px;
  }
  .zhuanjiakuback {
    margin: 0 12px;
  }
  .zhuanjiaku2 {
    width: 1200px;
  }
}

</style>
<style>
</style>
