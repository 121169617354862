<template>
  <div class="ZiXunDongTai">
    <div class="zixundongtai">
      <div class="mianbaoxie">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="mianbaoxie2"
        >
          <el-breadcrumb-item class="p1">您的位置：</el-breadcrumb-item>
          <el-breadcrumb-item class="p2" :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="p3">资讯动态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="liebiao">
        <div
          class="liebiao1"
          v-for="(item, index) in zxdtArrList"
          :key="index + 13"
          @click="geotoDetial(item)"
        >
          <img v-if="item.cover" :src="item.cover" class="img1" />
          <div v-else class="img1"></div>
          <div class="liebiao2">
            <div class="liebiao3">
              <!-- <el-link :underline="false" class="p4" -->
              {{ item.title }}
              <!-- </el-link -->
              <!-- > -->
              <!--            <p class="p4">{{item.title}} </p>-->
            </div>
            <div class="liebiao4">
              <p class="p5">{{ item.createTime.split(" ")[0] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
          background
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import http from "@/utils/http";

export default {
  name: "ZiXunDongTai",
  components: {
    Footer,
  },
  data() {
    return {
      menuType: null,
      total: 0,
      currentPage: 1,
      zxdtArrList: [],
      tableData: [
        {
          url: "src/imag/zixundongtai/img.png",
          name: "省卫生健康委组织委直属机关党员干部集中观看中共三大题材话剧《春园·1923》",
          time: "2023-07-07",
        },
        { url: "src/imag/zixundongtai/img.png", name: "1", time: "2023-07-07" },
      ],
    };
  },
  created() {
    if (this.$route.query.menuType) {
      this.menuType = this.$route.query.menuType;
    } else {
      this.menuType = '';
    }

    this.zxdtArr();
  },
  methods: {
    geotoDetial(item) {
      this.$router.push("/zixundongtaixiangqing?id=" + item.id + "&type=1");
    },
    handleCurrentChange(currentPage) {
      console.log("currentPage", currentPage);
      // 处理页码切换事件
      this.currentPage = currentPage;
      this.zxdtArr();
    },
    zxdtArr() {
      http
        .get("/api/public/article/page/", {
          params: {
            type: 1,
            timestamp: new Date().getTime(),
            current: this.currentPage,
            size: 10,
            menuType: this.menuType,
          },
        })
        .then((response) => {
          // 处理响应数据
          this.zxdtArrList = response.data.records;
          this.total = response.data.total;
          console.log("---------", this.zxdtArrList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    gotoUrl(router) {
      this.$router.push(router);
    },
  },
};
</script>

<style scoped>
.ZiXunDongTai {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.zixundongtai {
  display: flex;
  margin: 0 auto;
  margin-top: -300px;
  background: #ffffff;
  width: 1440px;
  height: 979px;
  justify-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.mianbaoxie {
  width: 1440px;
  height: 40px;
  display: flex;
  justify-items: center;
}
.liebiao {
  width: 1440px;
  height: 800px;
  display: flex;

  flex-direction: column;
}
.fenye {
  margin: 0 auto;
  display: flex;
  //width: 275px;
  height: 27px;
}
.mianbaoxie2 {
  width: 300px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  flex-direction: row;
}
.p1 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}
.p2 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}
.p3 {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 16px;
  color: #444444;
}
.liebiao1 {
  width: 1440px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.liebiao1:hover{
  background: #EFEFEF;
}
.img1 {
  width: 128px;
  height: 64px;
  display: flex;
}
.liebiao2 {
  width: 1272px;
  height: 64px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.liebiao3 {

  text-align: left;
  width: 800px;
  height: 18px;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.liebiao3:hover {
  cursor: pointer;
  color: #024395;

}
.p4 {
  /* margin: 0px; */
  width: 800px;
  /* display: flex; */
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.liebiao4 {
  width: 100px;
  height: 18px;
  opacity: 1;
  display: flex;
}
.p5 {
  margin: 0px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  /* 文字/888 */
  color: #888888;
  display: flex;
}
@media screen and (max-width: 1440px) {
  .zixundongtai {
    width: 1200px;
  }
  .mianbaoxie {
    width: 1200px;
  }
  .liebiao1 {
    width: 1200px;
  }
  .liebiao {
    width: 1200px;
  }
  .liebiao2 {
    width: 1032px;
  }
}
</style>
