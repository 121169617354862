<template>
  <div class="zixundongtaixiangqing">
    <div class="xiangqing">
      <div class="mianbaoxie">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="mianbaoxie2"
        >
          <el-breadcrumb-item class="p1">您的位置：</el-breadcrumb-item>
          <el-breadcrumb-item class="p2" :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
          <!-- <el-breadcrumb-item class="p3" :to="{ path: '/zixundongtai' }">{{
            nameTwo
          }}</el-breadcrumb-item> -->
          <el-breadcrumb-item class="p3">{{ nameTwo }}</el-breadcrumb-item>
          <el-breadcrumb-item class="p4">详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="xiangqing2">
<!--        <div class="biaoti">-->
<!--          <p class="pp">{{ zxdtxqList.title }}</p>-->
<!--          <div class="pp1">-->
<!--            <h class="h1">时间：{{ zxdtxqList.createTime }}</h>-->
<!--            <h class="h1" style="margin-left: 20px;">来源：{{ zxdtxqList.articleSource }}</h>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="tp">-->
<!--          <img :src="zxdtxqList.cover" class="img" />-->
<!--        </div>-->
        <div class="dibu" v-html="zxdtxqList.content"></div>
        <div style="cursor: pointer;text-align: left;" v-if="this.zxdtxqList.filePath && this.zxdtxqList.filePath !== '-1'" @click="handleDownload(zxdtxqList.filePath, zxdtxqList.fileName)">
          附件：{{this.zxdtxqList.fileName}}
        </div>
      </div>
      <div class="xiangqing3">
        <div class="button1" @click="gotoDetial(pre)" v-if="pre != null">
          <el-button circle class="el-icon-arrow-left"></el-button>
          <h class="button1-1"> 上一篇：{{ pre.title }} </h>
        </div>
        <div class="button2" style="margin-left: 72px" @click="gotoDetial(next)" v-if="next != null">
          <h class="button2-1"> 下一篇：{{ next.title }} </h>
          <el-button circle class="el-icon-arrow-right"></el-button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

import http from "@/utils/http";

export default {
  name: "ZiXunDongTaiXiangQing",
  components: {
    Footer,
  },
  data() {
    return {
      nameTwo: "通知公告",
      pre: {},
      next: {},
      xqid: "",
      type: 1,
      zxdtxqList: [],
      xiangqing: {
        biaoti: "加快落实家庭医生签约服务 奋力提高老年人健康管理服务水平",
        shijian: "时间：2023-8-7  09:38:58",
        laiyuan: "广东省卫生健康委员会",
        img: "",
        nr:
          "为推进省政府民生实事“以家庭医生签约服务推进城乡社区老年人健康管理工作”，8月1日，省卫生健康委组织召开全省家庭医生签约服务工作视频推进会。省卫生健康委党组成员、副主任汪洪滨出席会议并讲话。\n" +
          "\n" +
          "　　汪洪滨强调，当前正处于学习贯彻习近平新时代中国特色社会主义思想主题教育的重要阶段，落实好省政府民生实事，以家庭医生签约服务推进城乡社区老年人健康管理工作，是用实际行动落实主题教育“学思想、强党性、重实践、建新功”的总体要求。近年来，全省家庭医生签约服务工作在制度建设、能力提升、人员培养、服务优化等方面取得了新成效，有力地促进了分级诊疗制度的完善，进一步推进了医疗卫生体系整合化进程，是惠民生、得民心的福祉工程。2023年上半年，基层医疗卫生机构敬老爱老服务能力显著提高，1334间机构可向老年人提供个性化签约服务，在粤健通小程序和各地微信公众号等线上平台提供查询老年人健康档案服务，1540间机构设有志愿者服务，1811间机构门诊费用可实现医保统筹支付，1708间基层医疗卫生机构可开具长处方。403万名城乡社区65岁及以上老年人在城乡社区获得免费健康管理服务，按期完成省政府民生实事阶段性工作任务。\n" +
          "\n" +
          "　　会上，广州、深圳、佛山、珠海等4个地市围绕家庭医生签约服务高质量发展作经验交流发言，为全省家庭医生签约服务工作提供有益经验做法。会议还部署了2023年下半年推进家庭医生签约服务，特别是抓紧做好省政府民生实事的重点工作。一要锚定民生实事任务目标，联合有关部门加强动员、优化服务，努力提高老年人免费健康管理服务率，并通过医联体、医共体号源管理为签约居民优先提供专家资源，提升群众的获得感和幸福感。二要切实推动签约服务实现“六个拓展”，扩大服务来源、提升服务质量，使得签约服务更便捷、更灵活、更值得信赖。三要提升服务能力和效率，发挥基层的平台作用和服务优势，推动家庭医生签约服务从以治病为中心向以人民健康为中心转变。四要进一步健全激励保障机制，发挥医保、职称、家庭医生签约服务费等政策引导作用。五要加强宣传树立典型，努力打造一批富有特色、群众认可的典型地区、先进机构和优秀家庭医生，提高群众对家庭医生签约服务的知晓度。\n" +
          "\n" +
          "　　此次视频推进会在省卫生健康委设主会场，在21个地级以上市卫生健康局（委）及部分县区卫生健康局设分会场。省卫生健康委、省医保局、省中医药局、共青团省委有关负责同志在主会场参会，市县两级卫生、财政、医保、团委、中医药等有关部门和医疗卫生机构负责同志在分会场参加了会议。",
      },
    };
  },
  computed: {
    paragraphs() {
      return this.zxdtxqList.content.split("\n"); // 使用换行符分割为段落
    },
  },
  created() {
    this.xqid = this.$route.query.id;
    this.type = this.$route.query.type;
    if (this.$route.query.type2 == 1) {
      this.nameTwo = "通知公告";
    } else if(this.$route.query.type2 == 3){
      this.nameTwo = "薪火培基项目"
    } else if(this.$route.query.type2 == 4){
      this.nameTwo = "创优项目"
    } else {
      this.nameTwo = "资讯动态";
    }

    this.zxdtxqArr();
  },
  methods: {
    gotoDetial(item) {
      this.$router
        .replace("/zixundongtaixiangqing?id=" + item.id + "&type=" + this.type)
        .catch(() => {});
      window.location.reload();
    },
    zxdtxqArr() {
      var url = "/api/public/article/detail/" + this.xqid;
      if (this.type == "2") {
        url = "/api/public/article/project/detail/" + this.xqid;
      }

      http
        .get(url, {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.zxdtxqList = response.data;
          this.pre = response.data.pre;
          this.next = response.data.next;
          console.log("---------", this.zxdtxqList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    gotoUrl(router) {
      this.$router.push(router);
    },
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };
        xhr.send();
      });
    },
    handleDownload: function (url, name) {
      this.getBlob(url).then(blob => {
        this.saveAs(blob,name);
      });
    },
    saveAs(blob, filename) {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    }
  },
};
</script>

<style scoped>
::v-deep .dibu table {
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: 100%;
}
::v-deep .dibu table td, ::v-deep .dibu table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
  min-height: 30px;
  height: 30px;
}

.zixundongtaixiangqing {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.xiangqing {
  margin: 0 auto;
  align-items: center;
  width: 1440px;
  /* height: 1358px; */
  display: flex;
  margin-top: -300px;
  flex-direction: column;
  background: #ffffff;
  justify-content: space-around;
}
.mianbaoxie {
  width: 1440px;
  height: 40px;
  display: flex;
  justify-items: center;
}
.xiangqing2 {
  width: 1292px;
  margin-top: 30px;
  /* height: 1156px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.xiangqing3 {
  width: 1440px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
  padding-top: 36px;
}
.mianbaoxie2 {
  width: 320px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  flex-direction: row;
}
.p1 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}
.p2 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}
.p3 {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 16px;
  color: #929292;
}
.p4 {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 16px;
  color: #444444;
}
.biaoti {
  margin: 0 auto;
  width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tp {
  margin: 0 auto;
  width: 805px;
  height: 536px;
  display: flex;
}

.dibu p {
  width: 100%;
}
.pp {
  margin: 0 auto;
  display: flex;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  /* 文字/444 */
  color: #444444;
  opacity: 1;

  font-family: Source Han Sans CN;
}
.pp1 {
  margin: 0px auto;
  display: flex;
  height: 18px;
  text-align: center;
  padding-top: 16px;
}
.h1 {
  opacity: 1;
  font-family: Source Han Sans CN;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0px;
  /* 文字/888 */
  color: #8e8e8e;
  height: 18px;
  display: flex;
}
.img {
  width: 805px;
  height: 536px;
  display: flex;
}
.button1 {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.button2 {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.el-icon-arrow-left {
  width: 18px;
  height: 18px;
  display: flex;
  padding: 0px;
  margin-top: 2px;
  align-items: center;
  justify-content: center;
}
.button1-1 {
  margin: 0px;
  max-width: 580px;
  opacity: 1;
  display: flex;
  text-align: left;
  padding-left: 4px;
}
.el-icon-arrow-right {
  width: 18px;
  height: 18px;
  display: flex;
  padding: 0px;
  margin-top: 2px;
  align-items: center;
  justify-content: center;
}
.button2-1 {
  max-width: 580px;
  opacity: 1;
  display: flex;
  text-align: left;
  padding-right: 4px;
}

@media screen and (max-width: 1440px) {
  .xiangqing {
    width: 1200px;
  }
  .mianbaoxie {
    width: 1200px;
  }
  .xiangqing3 {
    width: 1200px;
  }
  .xiangqing2 {
    width: 1032px;
  }
}
</style>
