<template>
  <div class="JiCengPeiXun">
    <div class="jcpx">
      <div class="jcpx1">
        <div class="map-child-back-back">
          <el-button
            type="text"
            style="color: #024395 !important"
            v-if="showBack"
            @click="inintMap()"
          >
            <i class="el-icon-back"></i> 返回上级
          </el-button>
          <!-- <el-button v-if="showBack" @click="inintMap()">返回上级</el-button> -->
        </div>
        <div id="map-wrap" class="jcpx1-1"></div>
        <div class="jcpx1-2">
          <div class="jcpx1-2-1">
            <p class="p">全省机构排名 -</p>
          </div>

          <el-table :data="jgData" class="jcpx1-2-2" height="580px">
            <el-table-column
              label="机构名称"
              width="180"
              class="jcpx1-2-2-2"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="jgmc">{{ scope.row.cs }}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="地区"
              width="180"
              class="jcpx1-2-2-2"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="diqu">{{ scope.row.areaName }}</div>
              </template>
            </el-table-column>
            <el-table-column label="得分" width="160" class="jcpx1-2-2-2" prop="tgl">
              <template #default="{ row }">
                <div style="display: flex; flex-direction: column; width: 100%;">
                  <div style="display: flex;flex-direction: row; align-items: center; height: 20px; justify-content: flex-end;">
                    <p style="font-size: 10px;font-weight: 500;" :style="{color: getMax(parseFloat(row.insScore ? row.insScore : 0)) ? '#5282ca' : 'red'}">{{row.insScore ? row.insScore > 360 ? 360 : row.insScore : 0}}</p>
                    <p style="font-size: 10px;font-weight: 500;color: #444;">/360</p>
                  </div>
                  <div>
                    <el-progress
                        :format="formatPercentage"
                        :show-text="false"
                        :stroke-width="6"
                        :percentage="parseFloat(getJcdf(row.insScore ? row.insScore : 0))"
                        text-color="#FFFFFF"
                    ></el-progress>
                    <i class="el-icon-success" style="position: absolute;bottom: 8px;font-size: 12px;left: 60px;" :style="{color: getMax(parseFloat(row.insScore ? row.insScore : 0)) ? '#024395' : '#999'}"></i>
                  </div>
                </div>

              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

<!--      <div class="tongzhigonggao">-->
<!--        <div class="zixundongtai1">-->
<!--          <div class="zixundongtai2">-->
<!--            <p class="zixundongtai3">通知公告 -</p>-->
<!--          </div>-->
<!--          <div class="zixundongtai4">-->
<!--            <el-button class="zixundongtai5" @click="gotoUrl('/tongzhigonggao?menuType=1')"-->
<!--            >更多 ></el-button-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="tongzhigonggao1">-->
<!--          <div-->
<!--              class="tongzhigonggao2" style="width: 1342px;"-->
<!--              v-for="(item, index) in tzggList"-->
<!--              :key="index + 400"-->
<!--              @click="gotoZiXunDetialT(item)"-->
<!--          >-->
<!--            <div class="tongzhigonggao3">-->
<!--              {{ item.title }}-->
<!--            </div>-->
<!--            <div class="tongzhigonggao4">-->
<!--              <h class="tongzhigonggao4">{{ item.createTime }}</h>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="jcpx2">
        <div class="jcpx2-1">
          <div class="zixundongtai2">
            <p class="zixundongtai3">通知公告 -</p>
          </div>
          <div class="zixundongtai4">
            <el-button class="zixundongtai5" @click="gotoUrl('/tongzhigonggao?menuType=1')"
            >更多 ></el-button
            >
          </div>
        </div>
        <div class="jcpx2-2">
          <div
              class="jcpx2-2-1"
              v-for="(item, index) in tzggList"
              :key="index + 12"
              @click="gotoZiXunDetialT(item)"
          >
            <div class="jcpx2-2-2">
              <div class="pp1">
                {{ item.title }}
              </div>
              <p class="pp2">{{ item.createTime.split(" ")[0] }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="jcpx2">
        <div class="jcpx2-1">
          <div class="zixundongtai2">
            <p class="zixundongtai3">资讯动态 -</p>
          </div>
          <div class="zixundongtai4">
            <el-button class="zixundongtai5" @click="gotoList()"
              >更多 ></el-button
            >
          </div>
        </div>
        <div class="jcpx2-2">
          <div
            class="jcpx2-2-1"
            v-for="(item, index) in jcpxzxdtList"
            :key="index + 14"
            @click="gotoDetial(item)"
          >
            <div class="jcpx2-2-2">
              <div class="pp1">
                {{ item.title }}
              </div>
              <!--              <el-link :underline="false" class="pp1">{{ item.title }}</el-link>-->
              <p class="pp2">{{ item.createTime.split(" ")[0] }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="jcpx3">
        <div class="jcpx3-1">
          <div class="jcpx3-1-1">
            <p class="ppp1">薪火培基数据接入流程</p>
          </div>
        </div>
        <div class="jcpx3-2">
          <div class="jcpx-3-2-1">
            <img src="../imag/liuchengtu/img.png" class="img_p" />
            <p class="ppp2">培训数据接入申请</p>
          </div>
          <img src="../imag/liuchengtu/img_5.png" class="img_p1" />
          <div class="jcpx-3-2-1">
            <img src="../imag/liuchengtu/img_1.png" class="img_p" />
            <p class="ppp3">平台审核</p>
          </div>
          <img src="../imag/liuchengtu/img_5.png" class="img_p1" />
          <div class="jcpx-3-2-1">
            <img src="../imag/liuchengtu/img_2.png" class="img_p" />
            <p class="ppp2">平台开通接入账号</p>
          </div>
          <img src="../imag/liuchengtu/img_5.png" class="img_p1" />
          <div class="jcpx-3-2-1">
            <img src="../imag/liuchengtu/img_3.png" class="img_p" />
            <p class="ppp4">培训数据接入系统开发、测试</p>
          </div>
          <img src="../imag/liuchengtu/img_5.png" class="img_p1" />
          <div class="jcpx-3-2-1">
            <img src="../imag/liuchengtu/img_4.png" class="img_p" />
            <p class="ppp3">系统上线</p>
          </div>
        </div>
        <div class="jcpx3-3">
          <div class="jcpx3-3-1">
            <div
              class="jcpx3-3-2"
              v-for="(item, index) in wdxzList"
              :key="index + 20"
            >
              <img src="../imag/wendangxiazai/img.png" class="jcpx3-3-2-1" />
              <!--              <p class="jcpx3-3-2-2">培训数据接入申请表{{ wdxzList.url }}</p>-->
              <el-link :underline="false" class="jcpx3-3-2-2">
                <a :href="item.url" download target="_blank">
                  <div style="width: 140px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">
                    {{ item.name }}
                  </div>
                </a>
              </el-link>
            </div>
            <!--            <div class="jcpx3-3-2">-->
            <!--              <img src="../imag/wendangxiazai/img.png" class="jcpx3-3-2-1" />-->
            <!--              <p class="jcpx3-3-2-2">培训数据接入审核要求</p>-->
            <!--            </div>-->
            <!--            <div class="jcpx3-3-2">-->
            <!--              <img src="../imag/wendangxiazai/img.png" class="jcpx3-3-2-1" />-->
            <!--              <p class="jcpx3-3-2-2">数据接入开发技术规范</p>-->
            <!--            </div>-->
          </div>
        </div>

      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import echarts from "echarts";
import gdMapData from "@/assets/guangdong.json";
import http from "@/utils/http";
import chaozhou from "@/assets/chaozhou.json";
import dongguan from "@/assets/dongguan.json";
import foshan from "@/assets/foshan.json";
import guangzhou from "@/assets/guangzhou.json";
import heyuan from "@/assets/heyuan.json";
import huizhou from "@/assets/huizhou.json";
import jiangmen from "@/assets/jiangmen.json";
import maoming from "@/assets/maoming.json";
import meizhou from "@/assets/meizhou.json";
import qingyuan from "@/assets/qingyuan.json";
import shantou from "@/assets/shantou.json";
import shanwei from "@/assets/shanwei.json";
import shaoguan from "@/assets/shaoguan.json";
import shenzhen from "@/assets/shenzhen.json";
import xieyang from "@/assets/xieyang.json";
import yangjiang from "@/assets/yangjiang.json";
import yunfu from "@/assets/yunfu.json";
import zhanjiang from "@/assets/zhanjiang.json";
import zhaoqing from "@/assets/zhaoqing.json";
import zhongshan from "@/assets/zhongshan.json";
import zhuhai from "@/assets/zhuhai.json";
export default {
  components: { Footer },
  name: "JiCengPeiXun",
  data() {
    return {
      showBack: false,
      wdxzList: [],
      jcpxzxdtList: [],
      jsonMapList: {
        广州市: guangzhou,
        深圳市: shenzhen,
        佛山市: foshan,
        东莞市: dongguan,
        中山市: zhongshan,
        珠海市: zhuhai,
        江门市: jiangmen,
        肇庆市: zhaoqing,
        惠州市: huizhou,
        汕头市: shantou,
        潮州市: chaozhou,
        揭阳市: xieyang,
        汕尾市: shanwei,
        湛江市: zhanjiang,
        茂名市: maoming,
        阳江市: yangjiang,
        云浮市: yunfu,
        韶关市: shaoguan,
        清远市: qingyuan,
        梅州市: meizhou,
        河源市: heyuan,
      },
      jgData: [],
      tzggList: [],
    };
  },
  computed: {
    getJcdf: function () {
      return function (val) {
        let n = val / 360 > 100 ? 100 : val / 360;
        return n * 100;
      }
    },
    getMax: function() {
      return function (val) {
        return val > (360 * 0.4)
      }
    }
  },
  mounted() {
    this.inintMap();
    this.jcpxzxdtArr();
    this.wdxzData();
    this.tzggData()
  },
  methods: {
    formatPercentage: function (percentage){
      return `${percentage}`;
    },
    intChildMap(jsonMap) {
      this.showBack = true;
      http
        .get("/api/v2/statistics/index/out/area/map", {
          params: { regionCode: jsonMap.data.code },
        })
        .then((res) => {
          var oldChart = echarts.getInstanceByDom(
            document.getElementById("map-wrap")
          );
          if (oldChart) {
            // 如果存在旧的实例，先进行清空
            oldChart.dispose();
          }
          var mapChart = echarts.init(document.getElementById("map-wrap"));

          if (!echarts.getMap(jsonMap.name))
            echarts.registerMap(jsonMap.name, this.jsonMapList[jsonMap.name]);
          // mapChart的配置
          // 配置地图的基本信息
          var option = {
            title: {
              text: jsonMap.name,
              left: "center",
            },
            geo: {
              roam: false, // 禁止地图的移动、放大和缩小操作
            },
            tooltip: {
              trigger: "item",
              backgroundColor: "rgba(255,255,255,1)", // 设置提示框的背景颜色
              textStyle: {
                color: "#000000", // 设置提示框文本的颜色
                textAlign: "left",
              },
              extraCssText: "box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);",
              formatter: function (params) {
                var name = `<span style="text-align: left;color: #024395;">${params.name}</span>`;
                var customValue = params.data.haveInstitutionNum;
                var noInstitutionNum = params.data.noInstitutionNum;
                var institutionPercent = params.data.institutionPercent;

                return (
                    `<div style="display: flex;flex-direction: column; align-items: flex-start">
                        <span style="text-align: left;color: #024395; width: 100%;">${name}</span>
                        <span>已接入机构数：<span style="text-align: left;color: #024395;">${customValue}</span></span>
                        <span>未接入机构数：<span style="text-align: left;color: #024395;">${noInstitutionNum}</span></span>
                        <span>接入率：<span style="text-align: left;color: #024395;">${institutionPercent}%</span></span>
                    </div>`
                ); // 自定义tooltip的内容
              },
            },
            visualMap: {
              min: 0,
              max: 1000,
              left: "left",
              top: "bottom",
              inRange: {
                color: ["#CDE4FF", "#066AB0"],
              },
              text: ["High", "Low"],
              calculable: true,
            },
            series: [
              {
                name: jsonMap.name,
                type: "map",
                mapType: jsonMap.name, // 引用已注册的地图
                roam: false,
                label: {
                  show: true,
                  color: "#FFFFFF",
                },
                itemStyle: {
                  emphasis: { label: { show: true } },
                  borderColor: "#FFFFFF", // 设置地图边界线的颜色为白色
                },
                data: res.data,
              },
            ],
          };

          mapChart.setOption(option);
          //取消监听，不然会重复触发点击事件
          mapChart.off("click");
        });
    },
    inintMap() {
      var mydata = [];
      this.showBack = false;
      http.get("/api/v2/statistics/index/out/area/map").then((res) => {
        if (res.code == 0) {
          mydata = res.data;
          // 初始化echarts示例mapChart
          var mapChart = echarts.init(document.getElementById("map-wrap"));
          echarts.registerMap("广东", gdMapData);
          // mapChart的配置
          var option = {
            title: {
              text: "已接入机构区域分布 -",
              x: "left",
              left: "20",
              top: "20",
              textStyle: {
                color: "#024395",
              },
            },
            tooltip: {
              trigger: "item",
              backgroundColor: "rgba(255,255,255,1)", // 设置提示框的背景颜色
              textStyle: {
                color: "#000000", // 设置提示框文本的颜色
                textAlign: "left",
              },
              extraCssText: "box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);",
              formatter: function (params) {
                var name = `<span style="text-align: left;color: #024395;">${params.name}</span>`;
                var customValue = params.data.haveInstitutionNum;
                var noInstitutionNum = params.data.noInstitutionNum;
                var institutionPercent = params.data.institutionPercent;

                return (
                    `<div style="display: flex;flex-direction: column; align-items: flex-start">
                        <span style="text-align: left;color: #024395; width: 100%;">${name}</span>
                        <span>已接入机构数：<span style="text-align: left;color: #024395;">${customValue}</span></span>
                        <span>未接入机构数：<span style="text-align: left;color: #024395;">${noInstitutionNum}</span></span>
                        <span>接入率：<span style="text-align: left;color: #024395;">${institutionPercent}%</span></span>
                    </div>`
                );
              }, // 自定义tooltip的内容},
            },
            geo: {
              show: true,
              map: "广东",
              type: "map",
              itemStyle: {
                borderColor: "#FFFFFF", // 设置地图边界线的颜色为白色
                // 其他配置项...
              },
              label: {
                show: true,
                position: "inside",
                formatter: "{b}", // 显示地级市名称
                color: "#FFFFFF",
                // emphasis: {
                //   show: true,
                //   position: "inside", // 或者 'center'
                //   formatter: "{b}", // 圆圈状显示地级市名称
                //   borderWidth: 1, // 圆圈的边框宽度
                //   borderColor: "#FFFFFF", // 圆圈的边框颜色为白色
                //   // 其他配置项...
                // },
                // 其他配置项...
              },
              data: mydata,
            },
            series: [
              {
                name: "地级市", // series名称
                type: "map", // series图表类型
                map: "广东",
                coordinateSystem: "geo", // series坐标系类型
                itemStyle: {
                  borderColor: "#FFFFFF", // 设置地图边界线的颜色为白色
                  // 其他配置项...
                },
                label: {
                  show: true,
                  position: "inside",
                  formatter: "{b}", // 显示地级市名称
                  color: "#FFFFFF",
                },
                data: mydata, // series数据内容
                symbolSize: 10,
                hoverAnimation: true,
              },
              {
                name: "地级市", // series名称
                type: "scatter", // series图表类型
                map: "广东",
                coordinateSystem: "geo", // series坐标系类型
                symbol: "circle",
                symbolSize: 10,
                itemStyle: {
                  color: "transparent", // 设置内圆的颜色
                  borderColor: "#ffffff", // 设置外圆的颜色
                  borderWidth: 1, // 设置外圆的宽度
                },
                label: {
                  show: false,
                  position: "inside",
                  // formatter: "{b}", // 显示地级市名称
                  color: "#FFFFFF",
                  // emphasis: {
                  //   show: true,
                  //   position: "inside", // 或者 'center'
                  //   formatter: "{b}", // 圆圈状显示地级市名称
                  //   borderWidth: 1, // 圆圈的边框宽度
                  //   borderColor: "#FFFFFF", // 圆圈的边框颜色为白色
                  //   // 其他配置项...
                  // },
                  // 其他配置项...
                },
                data: mydata, // series数据内容

                hoverAnimation: true,
              },
              {
                name: "地级市", // series名称
                type: "scatter", // series图表类型
                map: "广东",
                coordinateSystem: "geo", // series坐标系类型
                symbol: "emptyCircle",
                symbolSize: 6,
                itemStyle: {
                  color: "#ffffff", // 设置内圆的颜色
                  // borderColor: 'transparent'// 设置外圆的颜色
                },
                label: {
                  show: false,
                  position: "inside",
                  // formatter: "{b}", // 显示地级市名称
                  color: "#FFFFFF",
                  // emphasis: {
                  //   show: true,
                  //   position: "inside", // 或者 'center'
                  //   formatter: "{b}", // 圆圈状显示地级市名称
                  //   borderWidth: 1, // 圆圈的边框宽度
                  //   borderColor: "#FFFFFF", // 圆圈的边框颜色为白色
                  //   // 其他配置项...
                  // },
                  // 其他配置项...
                },
                data: mydata, // series数据内容

                hoverAnimation: true,
              },
            ],
            visualMap: {
              min: 1,
              max: 100,
              left: "right",
              top: "bottom",
              text: ["接入率", " "], // 文本，默认为数值文本
              realtime: false,
              calculable: true,
              inRange: {
                color: ["#f3f3f3", "#066AB0"],
              },
            },
          };
          mapChart.setOption(option);
          //取消监听，不然会重复触发点击事件
          mapChart.off("click");
          //监听点击事件
          mapChart.on("click", (param) => {
            // console.log("--------", param);

            // if (param.data.hasChildren == "1") {
            //   this.intChildMap(param);
            // }
          });
        }
      });
    },
    jcpxzxdtArr() {
      http
        .get("/api/public/article/page/", {
          params: {
            timestamp: new Date().getTime(),
            current: 1,
            size: 10,
            menuType: 1,
            type: 1,
          },
        })
        .then((response) => {
          // 处理响应数据
          this.jcpxzxdtList = response.data.records;
          console.log("---------", this.jcpxzxdtList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    gotoDetial(item) {
      this.$router.push("/zixundongtaixiangqing?id=" + item.id + "&type=2&type2=3");
    },
    gotoList() {
      this.$router.push("/zixundongtai?menuType=1");
    },
    wdxzData() {
      http
        .get("/api/public/file/list", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.wdxzList = response.data;
          console.log("---------", this.wdxzList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
      http
        .get("/api/v2/statistics/index/out/area/rank", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.jgData = response.data;
          console.log("hhh", this.jgData);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    tzggData() {
      http
          .get("/api/public/article/list/2", {
            params: {
              timestamp: new Date().getTime(),
              menuType: '1',
            },
          })
          .then((response) => {
            // 处理响应数据
            this.tzggList = response.data;
            console.log("---------", this.tzggList);
          })
          .catch((error) => {
            // 处理错误
            console.error(error);
          });
    },
    gotoUrl(router) {
      this.$router.push(router);
    },
    gotoZiXunDetialT(item) {
      this.$router.push(
          "/zixundongtaixiangqing?id=" + item.id + "&type=1&type2=3"
      );
    },
  },
};
</script>

<style scoped>
.JiCengPeiXun {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.map-child-back-back {
  width: 100px;
  height: 63px;
  position: absolute;
  left: 650px !important;
  top: 50px !important;
  z-index: 100;
}
.jcpx {
  margin: 0px auto;
  margin-top: -300px;
  width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #ffffff;

  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.jcpx1 {
  margin: 0px auto;
  opacity: 1;
  width: 1397px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}
.jcpx2 {
  margin: 0px auto;
  opacity: 1;
  width: 1392px;
  display: flex;
  flex-direction: column;
}
.jcpx3 {
  margin: 0px auto;
  width: 1392px;
  min-height: 423px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.jcpx1-1 {
  margin: 0px;
  width: 784px;
  height: 705px;
  /* border: 1px solid; */
  border-radius: 20px;
  opacity: 1;
  display: flex;
  background-color: white;
  /* box-sizing: border-box; */
  /* 百千万/灰色背景渐变 */

  border-image: linear-gradient(270deg, #dcdcdc 0%, #f1f1f1 100%) 1;

  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.05);
}
.jcpx1-2 {
  width: 597px;
  height: 705px;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.jcpx1-2-1 {
  margin: 0px;
  width: 191px;
  height: 51px;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p {
  width: 159px;
  height: 35px;
  opacity: 1;
  margin: 0 auto;
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;

  z-index: 0;
}
.jcpx1-2-2 {
  width: 565px;
  height: 581px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow-y: scroll;
}

.jcpx1-2-2-2 {
  background: #f8f8f8;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jcpx2-1 {
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 51px;
  background-color: white;
}
.jcpx2-2 {
  display: flex;
  width: 100%;
  border-radius: 20px;
  opacity: 1;
  flex-direction: column;
  background: #fafafa;
  border-image: linear-gradient(270deg, #dcdcdc 0%, #f1f1f1 100%) 1;
  align-self: stretch;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.05);
  justify-content: flex-start;
  align-content: space-around;
}
.zixundongtai2 {
  display: flex;
  width: 127px;
  height: 51px;
  justify-content: center;
  align-items: center;
}
.zixundongtai3 {
  width: 121px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}
.zixundongtai4 {
  display: flex;
  width: 127px;
  height: 51px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.jcpx2-2-1 {
  display: flex;
  width: 100%;
  opacity: 1;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  justify-content: space-around;
}
.jcpx2-2-1:hover {
  background: #efefef;
}
.jcpx2-2-1:first-child:hover {
  /* 在这里添加首个子元素的 hover 样式 */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.jcpx2-2-1:last-child {
  border-bottom: none;
}
.jcpx2-2-1:last-child:hover {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.zixundongtai5 {
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  padding: 4px 16px;
  /* 文字/666 */
  color: #666666;
}
.jcpx2-2-2 {
  align-items: center;
  width: 1328px;
  height: 64px;
  opacity: 1;
  justify-content: space-between;
  /* 自动布局 */
  display: flex;
}
.pp1 {
  width: 950px;
  height: 18px;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  /* 文字/666 */
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.pp1:hover {
  cursor: pointer;
  color: #024395;
}
.pp2 {
  justify-content: flex-end;
  width: 150px;
  height: 18px;
  opacity: 1;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;

  /* 文字/888 */
  color: #888888;
}
.jcpx3-1 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 83px;
  opacity: 1;
  display: flex;
}

.jcpx3-2 {
  align-items: center;
  width: 100%;
  height: 154px;
  opacity: 1;
  display: flex;
}
.jcpx3-3 {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  opacity: 1;
  /* 百千万/蓝色渐变 */
  background: linear-gradient(270deg, #e5f2ff 0%, #b3d5ff 100%);
  padding: 10px 0;
}
.jcpx3-1-1 {
  width: 256px;
  height: 51px;
  opacity: 1;

  /* 自动布局 */
  display: flex;
}
.ppp1 {
  margin: 0 auto;
  width: 240px;
  height: 35px;
  opacity: 1;

  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
  display: flex;
}
.jcpx-3-2-1 {
  width: 192px;
  height: 154px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img_p {
  width: 120px;
  height: 120px;
  display: flex;
}
.ppp2 {
  display: flex;
  width: 144px;
  height: 18px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0px;

  /* 文字/444 */
  color: #444444;
}
.img_p1 {
  display: flex;
  width: 100px;
  height: 50px;
}
.ppp3 {
  display: flex;
  width: 72px;
  height: 18px;
  opacity: 1;

  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0px;

  /* 文字/444 */
  color: #444444;
}
.ppp4 {
  width: 234px;
  height: 18px;
  opacity: 1;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0px;

  /* 文字/444 */
  color: #444444;
}
.jcpx3-3-1 {
  width: 1200px;
  opacity: 1;
  margin: 0 auto;
  /* 自动布局 */
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}
.jcpx3-3-2 {
  width: 234px;
  height: 42px;
  opacity: 1;
  background: #ffffff;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid #96b8e1;

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  /* 自动布局 */
  display: flex;
}
.jcpx3-3-2-1 {
  display: flex;
  width: 24px;
  height: 24px;
}
.jcpx3-3-2-2 {
  width: 162px;
  height: 26px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  white-space: nowrap; /* 禁止文字换行 */
  overflow: hidden; /* 超出长度时隐藏溢出部分 */
  text-overflow: ellipsis; /* 超出长度时显示省略号 */
  color: #024395;
}
.jgmc {
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;

  /* 文字/666 */
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.diqu {
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;

  /* 文字/666 */
  color: #666666;
}
.zixundongtai {
  flex-direction: column;
  display: flex;
  width: 1392px;
  height: 523px;
  margin: 0 auto;

  margin-top: 8px;
}
.zixundongtai1 {
  justify-content: space-between;
  display: flex;
  width: 1392px;
  height: 51px;
  background-color: white;
}
.zixundongtai2 {
  display: flex;
  width: 127px;
  height: 51px;
  justify-content: center;
  align-items: center;
}
.zixundongtai3 {
  width: 121px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}
.zixundongtai4 {
  display: flex;
  width: 127px;
  height: 51px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.zixundongtai5 {
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  padding: 4px 16px;
  /* 文字/666 */
  color: #666666;
}
.tongzhigonggao {
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  margin-top: 8px;
  width: 1392px;
  min-height: 200px;
  max-height: 533px;
}
.tongzhigonggao1 {
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 8px;
  display: flex;
  width: 1392px;
  max-height: 464px;
  border: 1px solid #d3e7ff !important; /* 设置边框为实线 */
  padding: 16px 0px;
}
.tongzhigonggao2 {
  margin: 0 auto;
  display: flex;
  margin-top: 8px;
  width: 1392px;
  height: 36px;
  align-self: stretch;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
}
.tongzhigonggao2:hover {
  background: #efefef;
}
.tongzhigonggao3 {
  width: 800px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 500;
  color: #666666;
}
.tongzhigonggao3:hover {
  cursor: pointer;
  color: #024395;
}
.tongzhigonggao4 {
  display: flex;
  width: 150px;
  height: 20px;
  ont-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: right;

  /* 文字/888 */
  color: #888888;
}
@media screen and (max-width: 1440px) {
  .jcpx {
    width: 1200px;
  }
  .ChuangYouXiangMu1 {
    width: 1200px;
  }
  .jcpx1 {
    width: 1152px;
  }
  .jcpx1-2 {
    width: 357px;
  }
  .jcpx2 {
    width: 1152px;
  }
  .jcpx2-2-2 {
    width: 1088px;
  }
  .jcpx3 {
    width: 1152px;
  }
}
</style>
